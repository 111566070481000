
















import Vue from "vue";
import userManager from "@/services/userManager";

export default Vue.extend({
  data() {
    return {
      self: this,
      item: null,
      currentTab: "",
      permitted: false,
      tabs: {
        agencies: {
          label: "Khai báo agencies",
          condition() {
            return userManager.checkRole(["kt", "ksnb", "ksnbl", "mkt", "tp"]);
          },
        },
        cards: {
          label: "Thẻ ngân hàng TKCN",
          condition() {
            return userManager.checkRole(["kt", "ksnb", "ksnbl"]);
          },
        },
        costs: {
          label: "Upload dữ liệu CPQC",
          condition() {
            return userManager.checkRole(["kt", "ksnb", "ksnbl", "mkt", "tp"]);
          },
        },
        rd_costs: {
          label: "Upload dữ liệu Chốt RD",
          condition() {
            return userManager.checkRole(["kt", "ksnb", "ksnbl"]);
          },
        },
        bills: {
          label: "Upload dữ liệu hóa đơn",
          condition() {
            return userManager.checkRole(["kt", "ksnb", "ksnbl", "mkt", "tp"]);
          },
        },
        statistics: {
          label: "Tổng thống kê",
          condition() {
            return userManager.checkRole(["kt", "ksnb", "ksnbl", "mkt", "tp"]);
          },
        },
      },
    };
  },
});
